define("discourse/plugins/snapblocks-discourse/discourse/initializers/snapblocks-discourse", ["exports", "discourse/lib/plugin-api", "discourse/plugins/snapblocks-discourse/lib/snapblocks/snapblocks.min.es", "discourse/plugins/snapblocks-discourse/lib/snapblocks/translations-all-es"], function (_exports, _pluginApi, _snapblocksMin, _translationsAllEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function applySnapblocks(element, siteSettings) {
    async function renderElement(el) {
      let style = el.getAttribute("blockStyle") || siteSettings.block_style;
      _snapblocksMin.default.renderElement(el, {
        style: siteSettings.block_style,
        zebra: siteSettings.zebra_coloring,
        wrap: siteSettings.block_wrap,
        showSpaces: siteSettings.show_spaces,
        santa: siteSettings.santa_hats,
        scale: style.startsWith("scratch3") ? siteSettings.block_scale * 0.675 : siteSettings.block_scale,
        elementOptions: true
      });
    }
    element.querySelectorAll(".snapblocks-blocks").forEach(sb => {
      renderElement(sb);
    });
  }
  function initializeSnapblocks(api, siteSettings) {
    api.decorateCookedElement(el => applySnapblocks(el, siteSettings), {
      id: "snapblocks-discourse"
    });
    api.addComposerToolbarPopupMenuOption({
      action: function (toolbarEvent) {
        toolbarEvent.applySurround("\n" + `[snapblocks]` + "\n", "\n[/snapblocks]\n", "snapblocks_text", {
          multiline: false
        });
      },
      icon: "code",
      label: "snapblocks_discourse.title"
    });
  }
  var _default = _exports.default = {
    name: "apply-snapblocks",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      // console.debug(`snapblocks version: ${snapblocks.version}`);

      if (siteSettings.snapblocks_enabled) {
        (0, _translationsAllEs.default)(_snapblocksMin.default);
        (0, _pluginApi.withPluginApi)("1.15.0", api => {
          return initializeSnapblocks(api, siteSettings);
        });
      }
    }
  };
});